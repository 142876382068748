import {getUserData} from "@/auth/utils";
let userData = getUserData();
let nav = []
if(userData.role === 'admin'){
    nav.push({
        title: 'Overview',
        route: 'dashboard-admin',
        icon: 'UsersIcon',
        meta: {resource: 'iab',action: 'manage'}
    });
    nav.push({
        title: 'Practices',
        route: 'iab-practices',
        icon: 'BriefcaseIcon',
        meta: {resource: 'iab',action: 'manage'}
    });
}else{
    nav.push({
        title: 'Dashboard',
        route: 'dashboard-practice',
        icon: 'HomeIcon',
        meta: {resource: 'Practice',action: 'manage'}
    });
    nav.push({
        title: 'My Practice',
        icon: 'BriefcaseIcon',
        children: [
            {
                title: 'Details',
                route: 'iab-practice',
                meta: {resource: 'Practice',action: 'manage'}
            },
            {
                title: 'Owner(s)',
                route: {name: 'iab-owner-list'},
                meta: {resource: 'Owner',action: 'manage'}
            },
            {
                title: 'Staff',
                route: {name: 'iab-staff-list'},
                meta: {resource: 'Staff',action: 'manage'}

            },
            {
                title: 'Subcontractors',
                route: {name: 'iab-contractor-list'},
                resource: 'Subcontractor',
                action: 'manage'
            }
        ]
    });
    nav.push({
        title: 'My Clients',
        route: 'iab-client-list',
        icon: 'UsersIcon',
        resource: 'Client',
        action: 'manage'
    });
}
nav.push({
    title: 'My Tasks',
    route: 'iab-tasks-list',
    icon: 'CheckSquareIcon',
    resource: 'Task',
    action: 'manage'
});
if(userData.role === 'admin'){
    nav.push({
        title: 'Admin Panel',
        href: process.env.VUE_APP_ROOT_API+'/nova',
        icon: 'DatabaseIcon',
    });
    nav.push({
        title: 'Admin Videos',
        route: 'iab-admin-videos',
        icon: 'VideoIcon',
    });
}
nav.push({
    title: 'Faqs',
    route: 'iab-faqs',
    icon: 'PackageIcon',
    resource: 'Faq',
    action: 'manage'
});
nav.push({
    title: 'Resources',
    route: 'resources',
    icon: 'FileTextIcon',
    resource: 'Resources',
    action: 'manage'
});
export default nav